<template>
    <div class="subheader row-items">
        <div class="text" v-html="data.name"></div>
    </div>
</template>

<script>

export default {
    props: {
        data: Object,
        typeArray: String
    }
}

</script>